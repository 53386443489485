export const Config = 
{
URL:'https://api.docshafa.ir/api',

    Defination:{
        GetUnit:'/GetUnits',
        GetFiscalYear:'/GetFiscalYears',
        GetSets:'/GetSets',
        GetUsersWithUsernameandPass:'/GetUsersWithUsernameandPass',
        GetProducts:'/GetProducts',
        GetProductsWithCounts:'/GetProductsWithCounts',
        GetPosts:'/GetPosts',
        GetAllUsers:'/GetAllUsers',
        GetPostsAccessParts:'/GetPostsAccessParts',
        GetSetsOfProducts:'/GetSetsOfProducts',
        GetGroupOfSetsCollection:'/GetGroupOfSetsCollection',
        GetGroups:'/GetGroups',
        GetGroupsData:'/GetGroupsData',
        GetStates:'/GetStates',
        GetParts:'/GetParts',
        GetProductsDocuments:'/GetProductsDocuments',
        GetProductsDocumentsWithId:'/GetProductsDocumentsWithId',
        GetSetsDocumentsWithId:'/GetSetsDocumentsWithId',
        GetProductDocumentData:'/GetProductDocumentData',
        GetSetsDocumentData:'/GetSetsDocumentData',
        GetUserAccessParts:'/GetUserAccessParts',
        GetKardex:'/GetKardex',
        GetUpdateSetsListId:'/GetUpdateSetsListId',
        GetUpdateSets:'/GetUpdateSets',
        GetKardexProduct:'/GetKardexProduct',
        GetHtmlData:'/GetHtmlData',
        GetHtmlDataWithId:'/GetHtmlDataWithId',
        GetKardexSets:'/GetKardexSets',
        GetSetsDocuments:'/GetSetsDocuments',
        AddUnit:'/AddUnits',
        AddHtmlData:'/AddHtmlData',
        AddUsersAccessParts:'/AddUsersAccessParts',
        AddSetsOfProduct:'/AddSetsOfProduct',
        AddDocumentControlsCollection:'/AddDocumentControlsCollection',
        UpdateDocumentControls:'/UpdateDocumentControls',
        UpdateSetsOfProduct:'/UpdateSetsOfProduct',
        AddProductDocuments:'/AddProductDocuments',
        AddSetsDocuments:'/AddSetsDocuments',
        AddGroupOfSets:'/AddGroupOfSets',
        AddMovesWareHouse:'/AddMovesWareHouse',
        AddUsers:'/AddUsers',
        AddFiscalYear:'/AddFiscalYears',
        AddSets:'/AddSets',
        AddParts:'/AddParts',
        AddProducts:'/AddProducts',
        AddPosts:'/AddPosts',
        AddGroups:'/AddGroups',
        AddStates:'/AddStates',
        DeleteUnits:'/DeleteUnits',
        DeleteFiscalYear:'/DeleteFiscalYear',
        DeleteSets:'/DeleteSets',
        DeleteParts:'/DeleteParts',
        DeleteGroups:'/DeleteGroups',
        DeleteUsersAccessParts:'/DeleteUsersAccessParts',
        DeleteSetsOfProduct:'/DeleteSetsOfProduct',
        DeleteGroupOfSetsCollection:'/DeleteGroupOfSetsCollection',
        DeleteUsersWithUsername:'/DeleteUsersWithUsername',
        DeletePosts:'/DeletePosts',
        DeleteStates:'/DeleteStates',
        DeleteProducts:'/DeleteProducts',
        ResetPassword:'/ResetPassword',
        DeleteDocumentControlsSets:'/DeleteDocumentControlsSets',
        DeleteDocumentControlsProduct:'/DeleteDocumentControlsProduct',
        DeleteDocumentControls:"/DeleteDocumentControls",
        DeleteHtmlData:"/DeleteHtmlData",
        DeleteMovesWareHouse:"/DeleteMovesWareHouse",

    }
  



}